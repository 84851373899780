import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { combineLatest } from 'rxjs';
import { filter, switchMap, take, tap } from 'rxjs/operators';
import {
  AuthSelectors,
  BaseAppState,
  StudyCenter,
  StudyCenterApiActions,
  StudyCenterSelectors,
  UsersApiActions,
  UsersSelectors,
} from 'shared';

@Component({
  selector: 'app-user-list',
  templateUrl: './user-list.component.html',
  styleUrls: ['./user-list.component.scss'],
})
export class UserListComponent implements OnInit {

  readonly processKeys = [
    UsersApiActions.getUsers.type,
    StudyCenterApiActions.getStudyCenterById.type,
    UsersApiActions.addUser.type,
    UsersApiActions.editUser.type,
  ];

  areUsersFetched$ = this.store.pipe(select(UsersSelectors.areUsersPartiallyFetched));
  loggedInUserStudyCenterId$ = this.store.pipe(select(AuthSelectors.getLoggedInStudyCenterId));
  studyCenter$ = this.loggedInUserStudyCenterId$.pipe(
    filter((id: string | undefined) => Boolean(id)),
    take(1),
    switchMap((id: string) => this.store.pipe(select(StudyCenterSelectors.getStudyCenterById(id)))),
  );

  constructor(private store: Store<BaseAppState>) { }

  ngOnInit(): void {
    this.fetchData();
  }

  private fetchData() {
    combineLatest([
      this.loggedInUserStudyCenterId$,
      this.areUsersFetched$,
      this.studyCenter$,
    ]).pipe(
      filter(([studyCenterId, _fetched, _studyCenter]: [string, boolean, StudyCenter | undefined]) => Boolean(studyCenterId)),
      take(1),
      tap(([studyCenterId, usersFetched, studyCenter]: [string, boolean, StudyCenter | undefined]) => {
        if (!usersFetched) {
          this.store.dispatch(UsersApiActions.getUsersForStudyCenter({ studyCenterId: studyCenterId }));
        }

        if (!Boolean(studyCenter)) {
          this.store.dispatch(StudyCenterApiActions.getStudyCenterById({ id: studyCenterId }));
        }
      }),
    ).subscribe();
  }

}
