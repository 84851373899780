export const STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS = {
  usersRoot: 'users',
  participantDetailsRoot: 'participant-details',
  users: {
    listElement: 'all',
  },
  participantDetails: {
    participantDetailsElement: ':patientId',
  },
};

export const STUDY_CENTER_MANAGEMENT_PATHS = {
  users: {
    // tslint:disable-next-line: max-line-length
    list: `/${STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.usersRoot}/${STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.users.listElement}`,
  },
  participantDetails: {
    patient: (participantId: string) => `/${STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.participantDetailsRoot}/${participantId}`,
  },
};
