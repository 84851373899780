import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseAppState } from 'shared';

import { goToUsersList } from '../../../core/router/actions/study-center-management-navigation.actions';

@Component({
  selector: 'app-participant-details',
  templateUrl: './participant-details.component.html',
  styleUrls: ['./participant-details.component.scss'],
})
export class ParticipantDetailsComponent {

  constructor(private store: Store<BaseAppState>) { }

  handleBackClick() {
    this.store.dispatch(goToUsersList());
  }

}
