import { Routes } from '@angular/router';
import { LoggedInGuard, UserRole, UserRoleGuard } from 'shared';

import {
  ParticipantDetailsComponent,
} from '../../users-study-center-management/components/participant-details/participant-details.component';
import { UserListComponent } from '../../users-study-center-management/components/user-list/user-list.component';
import {
  STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS,
  STUDY_CENTER_MANAGEMENT_PATHS,
} from './definitions/study-center-management-navigation.definitions';

export const routes: Routes = [
  {
    path: STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.usersRoot,
    canActivate: [LoggedInGuard, UserRoleGuard],
    data: {
      roles: [UserRole.StudyCenterManagement],
    },
    children: [
      {
        path: STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.users.listElement,
        pathMatch: 'full',
        component: UserListComponent,
      },
      {
        path: '**',
        redirectTo: STUDY_CENTER_MANAGEMENT_PATHS.users.list,
      },
    ],
  },
  {
    path: STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.participantDetailsRoot,
    canActivate: [LoggedInGuard, UserRoleGuard],
    data: {
      roles: [UserRole.StudyCenterManagement],
    },
    children: [
      {
        path: STUDY_CENTER_MANAGEMENT_PATH_ELEMENTS.participantDetails.participantDetailsElement,
        pathMatch: 'full',
        component: ParticipantDetailsComponent,
      },
    ],
  },
];
