import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { EffectsModule } from '@ngrx/effects';

import { StudyCenterManagementNavigationEffects } from './effects/study-center-management-navigation.effects';
import { routes } from './study-center-management.routes';

@NgModule({
  imports: [
    CommonModule,
    RouterModule.forChild(routes),
    EffectsModule.forFeature([
      StudyCenterManagementNavigationEffects,
    ]),
  ],
})
export class StudyCenterManagementRouterModule {}
