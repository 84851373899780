import { Component, OnInit } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest } from 'rxjs';
import { filter, take, tap } from 'rxjs/operators';
import { AuthSelectors, BaseAppState, UsersApiActions } from 'shared';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {

  loggedInUserId$ = this.store.pipe(select(AuthSelectors.getLoggedInUserId));
  loggedInUserStudyCenterId$ = this.store.pipe(select(AuthSelectors.getLoggedInStudyCenterId));

  constructor(
    private store: Store<BaseAppState>,
    private translateService: TranslateService,
  ) { }

  ngOnInit() {
    this.translateService.setDefaultLang('en');

    combineLatest([
      this.loggedInUserId$,
      this.loggedInUserStudyCenterId$,
    ]).pipe(
      filter(([userId, centerId]: [string, string]) => Boolean(userId) && !Boolean(centerId)),
      take(1),
      tap(([userId, _centerId]: [string, string]) => this.store.dispatch(UsersApiActions.getUser({ userId: userId }))),
    ).subscribe();
  }

}
