import { Routes } from '@angular/router';

import { STUDY_CENTER_MANAGEMENT_PATHS } from './core/router/definitions/study-center-management-navigation.definitions';

export const routes: Routes = [
  {
    path: '**',
    redirectTo: STUDY_CENTER_MANAGEMENT_PATHS.users.list,
  },
];
