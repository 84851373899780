<app-nav-bar></app-nav-bar>
<main>
  <div class="app-container pt-58" [ngClass]="(loggedInUserId$ | async) ? 'd-flex' : 'bg-dark'">
    <ng-container *ngIf="(loggedInUserId$ | async)">
      <app-side-nav></app-side-nav>
    </ng-container>

    <div [ngClass]="(loggedInUserId$ | async) ? 'side-nav-width' : 'logged-out-height'">
      <router-outlet></router-outlet>
    </div>
  </div>
</main>
