<div class="side-nav-container bg-dark d-flex flex-column justify-content-between">
  <nav class="navbar-dark pt-4 pl-4">
    <ul class="navbar-nav">
      <li class="nav-item">
        <a class="nav-link d-flex align-items-center" [class.active-nav-link]="(firstUrlSegment$ | async) === 'users'" (click)="handleGoToUsersClick()">
          <span class="material-icons mr-2">home</span>
          {{'users.multiple' | translate}}
        </a>
      </li>
    </ul>
  </nav>

  <div class="d-flex justify-content-center">
    <nav class="navbar-dark">
      <ul class="navbar-nav list-group list-group-horizontal">
        <li class="nav-item mr-2">
          <a class="nav-link" (click)="handleSetLanguageClick('de')">German</a>
        </li>
        <li class="nav-item ml-2">
          <a class="nav-link" (click)="handleSetLanguageClick('en')">English</a>
        </li>
      </ul>
    </nav>
  </div>
</div>
